import { format, distanceInWords, differenceInDays } from "date-fns";
import React from "react";
import { Link } from "gatsby";
import { cn, buildImageObj } from "../lib/helpers";
import { imageUrlFor } from "../lib/image-url";
import BlockContent from "./block-content";
import BlockText from "./block-text";
import Container from "./container";
import RoleList from "./role-list";
import { Figure } from "./figure";

import { responsiveTitle3, link } from "./typography.module.css";
import { text, details } from "./project-preview.module.css";
import * as styles from "./project.module.css";

function Project(props) {
  const {
    _rawBody,
    title,
    categories,
    mainImage,
    members,
    publishedAt,
    relatedProjects,
    _rawDescription,
    _rawVideo,
    _rawGallery,
    _rawAdditionalInfo,
    location,
  } = props;

  console.log(_rawGallery);
  return (
    <article className={styles.root}>
      <Container>
        <div className={styles.gridLayout}>
          <div className={styles.mainContent}>
            <h1 className={styles.title}>{title}</h1>
            {location && <div className={styles.title}>{location}</div>}
          </div>
          <aside className={styles.metaContent}>
            <div>
            
                {_rawDescription && (
                  <BlockText blocks={_rawDescription} />
                )}
                {/* {categories?.length > 0 && <li><h4>Credits</h4><div>{categories.map((category, index) => <React.Fragment>{category.title}{index < categories.length - 1 && ", "}</React.Fragment>)}</div></li>} */}
                {/* {_rawBody && <li><h4>Summary</h4><div>{_rawBody && <BlockContent blocks={_rawBody || []} />}<br /></div></li>} */}
            
            </div>
          </aside>
          
        </div>
        <div className={styles.gallery}>
            {_rawGallery?.map((image, index) => (<React.Fragment key={image.asset?.id}><Figure columns={image.columns} node={image} />{index % 2 ? <></> : <></>}</React.Fragment>))}
          </div>
      </Container>
    </article>
  );
}

export default Project;
