import React from "react";
import { graphql, Link } from "gatsby";
import Layout from "../containers/layout";
import SEO from "../components/seo";
import Container from "../components/container";
import GraphQLErrorList from "../components/graphql-error-list";
import Project from "../components/project";


export const query = graphql`
  query ProjectTemplateQuery($id: String!) {
    project: sanityProject(id: { eq: $id }) {
      id
      publishedAt
      mainImage {
        crop {
          _key
          _type
          top
          bottom
          left
          right
        }
        hotspot {
          _key
          _type
          x
          y
          height
          width
        }
        asset {
          _id
          url
        }
        alt
      }
      title
      slug {
        current
      }
      _rawExcerpt
      _rawDescription
      _rawGallery(resolveReferences: {maxDepth: 20})
      location
    }
  }
`;

const ProjectTemplate = props => {
  const { data, errors, location } = props;
  const project = data && data.project;
  const seoImage = data.project ? `${project.mainImage.asset.url}?w=1200&h=600` : "";
  // console.log(seoImage);
  return (
    <>
    <Layout location={location}>
      {errors && <SEO title="GraphQL Error" />}
      {project && <SEO title={project.title || "Untitled"} image={seoImage} />}

      {errors && (
        <Container>
          <GraphQLErrorList errors={errors} />
        </Container>
      )}
      <div>
        {project && <Project {...project} />}
      </div>
    </Layout>
    <div className=" w-full text-center hidden md:block fixed bottom-0"><Link to="/projects"><a className="text-mobile-sm uppercase py-7.5 block">Back</a></Link></div>
    </>
  );
};

export default ProjectTemplate;
